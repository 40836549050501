.pnc-button {
  transition: all 0.2s ease-out;
  margin-top: 20px;
  padding: 2em 2em 2em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ede6e6;
  border-radius: 10px;
  width: 45%;
  cursor: pointer;
}

.pnc-button:hover {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 26%);
}
@media only screen and (max-width: 640px) {
  .pnc-button {
    width: 100%;
  }
}
