.accordion-toggle-button {
  background-color: white;
  width: 90%;
  border: none;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
  cursor: pointer;
}
.inbox-text {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 5px;
}
.conversations-button {
  /* background-color: black; */
  padding: 20px;
  border-radius: 500px;
  margin: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 12%;
}
.conversations-text {
  color: aliceblue;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 0;
}
.pdf-container {
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  background-color: blue;
}
