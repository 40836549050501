.gradient-button {
  background: linear-gradient(
    -45deg,
    #ee7752,
    #e73c7e,
    #23a6d5,
    #23d5ab,
    #14b29f
  );
  background-size: 400% 400%;
  animation: buttonTransition 5s ease-in-out infinite;
  animation-direction: alternate;
  transition: all;
  border: 0px;
  width: 100%;
  padding: 10px 24px !important;
  color: white !important;
  border-radius: 100px !important;
}
@keyframes buttonTransition {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
