.acd-button {
  background-color: white;
  padding: 8px 20px;
  border-radius: 500px;
  /* margin: 5px;
  margin-left: 30px;
  margin-right: 30px; */
  display: flex;
  cursor: pointer;
  align-items: center;
}
.acd-button-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  padding: 8px 20px;
  border-radius: 500px;
  /* margin: 5px;
  margin-left: 30px;
  margin-right: 30px; */
  display: flex;
  cursor: pointer;
  align-items: center;
}
.acd-text {
  color: black;
  margin-bottom: 0;
  margin-left: 10px;
}
