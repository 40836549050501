.search-container {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  border-radius: 10px;
  display: flex;
  padding: 5px 12px;
  align-items: center;
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 12px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: none !important;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.5s ease-in-out, box-shadow 0.15s ease-in-out !important;
  background: none !important;
}
.assigned-text {
  margin: 0;
  font-weight: 700 !important;
  font-size: 1rem !important;
}
.toggle-back {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  padding: 5px 0px;
}
.button-toggle-on {
  margin: 0;
  font-size: 1rem;
  background-color: white;
  width: 45%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.button-toggle-off {
  margin: 0;
  font-size: 1rem;
  width: 45%;
  border-radius: 5px;
  cursor: pointer;
}
.profilePic {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.green-light {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #14b29f;
}
.custom-name-design {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  padding-right: 20px;
  border-radius: 100px;
  margin-right: 5px;
}
.custom-check-design {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  width: 45px;
  height: 45px;
  border-radius: 30px;
  align-self: center;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-styling {
  padding: 10px;
  border-width: 2px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px !important;
  --tw-bg-opacity: 1;
  border-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  max-width: 80%;
}
.message-input-box {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px !important;
  --tw-bg-opacity: 1;
  border-color: rgba(241, 241, 241, var(--tw-bg-opacity));
}
.message-input-field {
  margin: 10px;
  border: none;
  align-self: flex-start;
  width: 95%;
  height: 80%;
  outline: none !important;
  border: none;
}
input:focus {
  outline: none !important;
  border: none;
}

.send-button {
  display: flex;
  align-items: center;
  background-color: #ff2e56;
  border-radius: 200px;
  padding: 10px;
  color: white;
  cursor: pointer;
}
/* .inbox-body-style {
  display: flex;
  flex-direction: column;
  height: 100%;

} */
.back-button {
  display: none !important;
}
@media only screen and (max-width: 900px) {
  .back-button {
    display: block !important;
    font-size: 30px;
    cursor: pointer;
  }
  .message-input-box {
    flex-direction: row;
  }
  .message-styling {
    max-width: 100%;
  }
  .custom-check-design {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (max-height: 800px) {
  .message-input-box {
    flex-direction: row;
  }
}
