.drop-down {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
.input-class {
  height: 100% !important;
  width: 100% !important;
}
.container-class {
  width: 100%;
}
